import { ValidityAreaLanguages } from "../../types/types";

export const valditiyAreaLanguageDefault: ValidityAreaLanguages[] = [
    {
        rating: "PUBLIC",
        validityArea: 'AD',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'AE',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'AF',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'AL',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'AM',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'AR',
        languages: ['ES']
    },
    {
        rating: "PUBLIC",
        validityArea: 'AT',
        languages: ['DE']
    },
    {
        rating: "PUBLIC",
        validityArea: 'AU',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'AZ',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'BA',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'BD',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'BE',
        languages: ['DE', 'FR', 'NL']
    },
    {
        rating: "PUBLIC",
        validityArea: 'BG',
        languages: ['BG']
    },
    {
        rating: "PUBLIC",
        validityArea: 'BH',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'BN',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'BO',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'BR',
        languages: ['Z9']
    },
    {
        rating: "PUBLIC",
        validityArea: 'BT',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'BY',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'BZ',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'CA',
        languages: ['3F', 'Z8']
    },
    {
        rating: "PUBLIC",
        validityArea: 'CH',
        languages: ['DE', 'FR', 'IT']
    },
    {
        rating: "PUBLIC",
        validityArea: 'CL',
        languages: ['ES']
    },
    {
        rating: "PUBLIC",
        validityArea: 'CN',
        languages: ['ZH']
    },
    {
        rating: "PUBLIC",
        validityArea: 'CO',
        languages: ['ES']
    },
    {
        rating: "PUBLIC",
        validityArea: 'CR',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'CU',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'CY',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'CZ',
        languages: ['CS']
    },
    {
        rating: "PUBLIC",
        validityArea: 'DE',
        languages: ['DE']
    },
    {
        rating: "PUBLIC",
        validityArea: 'DK',
        languages: ['DA']
    },
    {
        rating: "PUBLIC",
        validityArea: 'DO',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'DZ',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'EC',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'EE',
        languages: ['ET']
    },
    {
        rating: "PUBLIC",
        validityArea: 'EG',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'ES',
        languages: ['ES']
    },
    {
        rating: "PUBLIC",
        validityArea: 'FI',
        languages: ['FI']
    },
    {
        rating: "PUBLIC",
        validityArea: 'FR',
        languages: ['FR']
    },
    {
        rating: "PUBLIC",
        validityArea: 'GE',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'GR',
        languages: ['EL']
    },
    {
        rating: "PUBLIC",
        validityArea: 'GT',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'HK',
        languages: ['ZH']
    },
    {
        rating: "PUBLIC",
        validityArea: 'HN',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'HR',
        languages: ['HR']
    },
    {
        rating: "PUBLIC",
        validityArea: 'HU',
        languages: ['HU']
    },
    {
        rating: "PUBLIC",
        validityArea: 'ID',
        languages: ['ID']
    },
    {
        rating: "PUBLIC",
        validityArea: 'IE',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'IL',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'IN',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'IQ',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'IR',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'IS',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'IT',
        languages: ['IT']
    },
    {
        rating: "PUBLIC",
        validityArea: 'JM',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'JO',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'JP',
        languages: ['JA']
    },
    {
        rating: "PUBLIC",
        validityArea: 'KE',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'KH',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'KP',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'KR',
        languages: ['KO']
    },
    {
        rating: "PUBLIC",
        validityArea: 'KW',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'KZ',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'LA',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'LB',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'LI',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'LK',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'LT',
        languages: ['LT']
    },
    {
        rating: "PUBLIC",
        validityArea: 'LU',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'LV',
        languages: ['LV']
    },
    {
        rating: "PUBLIC",
        validityArea: 'MA',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'MC',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'MD',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'ME',
        languages: ['SH']
    },
    {
        rating: "PUBLIC",
        validityArea: 'MK',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'MM',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'MT',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'MX',
        languages: ['ES']
    },
    {
        rating: "PUBLIC",
        validityArea: 'MY',
        languages: ['MS']
    },
    {
        rating: "PUBLIC",
        validityArea: 'NI',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'NL',
        languages: ['NL']
    },
    {
        rating: "PUBLIC",
        validityArea: 'NO',
        languages: ['NO']
    },
    {
        rating: "PUBLIC",
        validityArea: 'NP',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'NZ',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'OM',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'PA',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'PE',
        languages: ['ES']
    },
    {
        rating: "PUBLIC",
        validityArea: 'PG',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'PH',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'PK',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'PL',
        languages: ['PL']
    },
    {
        rating: "PUBLIC",
        validityArea: 'PR',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'PT',
        languages: ['PT']
    },
    {
        rating: "PUBLIC",
        validityArea: 'PY',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'QA',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'RO',
        languages: ['RO']
    },
    {
        rating: "PUBLIC",
        validityArea: 'RS',
        languages: ['SH']
    },
    {
        rating: "PUBLIC",
        validityArea: 'RU',
        languages: ['RU']
    },
    {
        rating: "PUBLIC",
        validityArea: 'SA',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'SE',
        languages: ['SV']
    },
    {
        rating: "PUBLIC",
        validityArea: 'SG',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'SI',
        languages: ['SL']
    },
    {
        rating: "PUBLIC",
        validityArea: 'SK',
        languages: ['SK']
    },
    {
        rating: "PUBLIC",
        validityArea: 'SM',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'SV',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'TH',
        languages: ['TH']
    },
    {
        rating: "PUBLIC",
        validityArea: 'TJ',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'TM',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'TN',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'TR',
        languages: ['TR']
    },
    {
        rating: "PUBLIC",
        validityArea: 'TW',
        languages: ['ZF']
    },
    {
        rating: "PUBLIC",
        validityArea: 'UA',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'UK',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'US',
        languages: ['ES', 'Z8']
    },
    {
        rating: "PUBLIC",
        validityArea: 'UY',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'UZ',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'VE',
        languages: ['ES']
    },
    {
        rating: "PUBLIC",
        validityArea: 'VN',
        languages: ['VI']
    },
    {
        rating: "PUBLIC",
        validityArea: 'YE',
        languages: ['EN']
    },
    {
        rating: "PUBLIC",
        validityArea: 'ZA',
        languages: ['EN']
    },

];
