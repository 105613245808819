import { SafetyDataSheet } from './types/types';
import {CN} from './constants';
import { environment } from './env/env';


  export const availableLang = environment.validityArea != CN ? ['de', 'en', 'es', 'fr', 'zh', 'ja', 'it', 'pt'] : ['en', 'zh'];

  export function splitName(name:string): any {
      let tempArray = name.split(' ');
      switch (tempArray.length) {
          case 1: {
            let [tempFirstName] = tempArray;
            return {firstName: tempFirstName, lastName: ''};
          }
          case 2: {
            let [tempFirstName, tempLastName] = tempArray;
            return {firstName: tempFirstName, lastName: tempLastName};
          }
          case 3: {
            let [tempFirstName, tempMiddleName, tempLastName] = tempArray;
            return {firstName: `${tempFirstName} ${tempMiddleName}`, lastName: tempLastName};
          }
      }
      return
  }

  export function replaceHashFromArticleNumber(sds: SafetyDataSheet): SafetyDataSheet {
    sds.section_one.articleNumber = sds.section_one.articleNumber.replace('###', " ");
    return sds;
  }

  export function checkLanguagePresent(language: String) {
    const extractedLang = language.split('-')[0] || '';
    return availableLang.find(lang => extractedLang == lang);
  }

  function convertMMtoPixel(sizeInMM: number) {
    return (300 * sizeInMM) / 25.4;
  }

  export function convertPixeltoMM(pixel: number) {
    return (25.4 * pixel) / 300;
  }


  export const template = {
    big: {width: 155, height: 50},
    old: {width: 155, height: 25},
    medium: {width: 100, height: 30},
    milliseq: {height: 28.5, width: 43.5},
    small: {width: 29, height: 25},
  }


  export function converTemplateMMtoPixel(templateName: string) {
    const dim = template[`${templateName}`];
    const width = convertMMtoPixel(dim.width)
    const height = convertMMtoPixel(dim.height);
    return {width, height};
  }

  export function calculateSDS_Length(sds: SafetyDataSheet): Number {
    const {hazardStatements, hazardStatementsSec, precautionaryStatements, otherHazards} = sds.section_two;
          const {response=[], storage=[], disposal=[], prevention=[]} = precautionaryStatements;
          let tempHazardState = hazardStatements.toString().replace(/,/g,'');
          let tempHazardStatementsSec = hazardStatementsSec.toString().replace(/,/g,'');
          let tempOtherHazards = otherHazards.toString().replace(/,/g,'');
          let tempResponse = response.toString().replace(/,/g,'');
          let tempStorage = storage.toString().replace(/,/g,'');
          let tempDisposal = disposal.toString().replace(/,/g,'');
          let tempPrevention = prevention.toString().replace(/,/g,'');
          let checkLength = tempHazardState.length + tempHazardStatementsSec.length + tempOtherHazards.length + tempResponse.length + tempStorage.length + tempDisposal.length + tempPrevention.length;
          return checkLength;
  }