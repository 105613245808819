export const environment = {
    baseUrl: 'https://mapsapi.merckgroup.com/api', // URL to server API
    // baseUrl: 'http://maps-api-apiloadb-cbkabgr8blr-751915954.eu-central-1.elb.amazonaws.com/api', // URL to server API
    apiVersion: 'v3',
    safetyDataAPIBaseURL: 'https://mymsafety-prod.merckgroup.com/api/v1', // Production URL
    // filterBaseUrl: 'http://maps-api-apiloadb-cbkabgr8blr-751915954.eu-central-1.elb.amazonaws.com/api',
    IFUDocAPIBaseURL: 'https://mymsafety-dev.merckgroup.com/api/v1/product/sial/search?product=',
    rating: 'PUBLIC',
    validityArea: 'US',
    language: 'Z8',
    defaultLanguage: 'en',
    region:"US",
    storageVersion: 2,
    clientId: 'com.emd.mymsafety.service',
    redirectURI: 'https://www.mymsafety.com/login',
    domain: 'https://www.mymsafety.com',
    // redirectURI: 'https://d9vhnhqo5bmii.cloudfront.net/login',
    // domain: 'https://d9vhnhqo5bmii.cloudfront.net/',
    requestFrom: 0,
    webRequestFrom: 2,
    displayName: 'My M Safety',
    sentryDsn: 'YOUR_SENTRY_DNS',
    storeAppURL: {
        // ios: 'id1440030082',
        ios: 'id1442236510',
        android: 'market://details?id=com.emd.mymsafety.googleplay',
    },
    hasMultiPrivacyPolicyLang: false // Used to display language selector in privacy page
};
