import { Component } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { LoadingProvider } from '../../services/loading/loading';

@Component({
    selector: 'indeterminate-progress',
    templateUrl: 'indeterminate-progress.html',
    styleUrls: ['indeterminate-progress.scss'],
    animations: [
        trigger('progressAnim', [
            transition('void => *', [
                style({ height: '0px' }),
                animate('250ms ease-out')
            ]),
            transition('* => void', [
                animate('299ms ease-in', style({ height: '0px' }))
            ])
        ])
    ]
})
export class IndeterminateProgressComponent {

    constructor(public loading: LoadingProvider) {

    }

}
