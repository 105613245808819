import { LocationService } from '../services/location.service';
import { environment } from '../env/env';

export interface SearchResult {
    hits: number;
    results: Article[];
}

export interface Article {
    id?: number,
    articleNumber: string;
    articleName: string;
    casNumber: string;
    substance: string;
    system: string;
    offlineAvailable?: boolean,
    materialNumber: string;
    batchNumber?: string; // batchNumber retrieved from label scanning
    template?: string;
    amount?: string;
    fillType?: string;
    materialDate?: any;
    extraText?: string;
    canvas?: string;
    ngClass?: string;
    rotated_by?: number;
    rotateByClass?: string;
}

export class DefaultFavoriteList implements FavoritesList {
  id = 0;
  name = 'Default';
  expandList = false;
  articles = [];
}

export interface FavoritesList {
  uuid?: string,
  id?: number,
  name: string,
  articles: Article[],
  offlineAvailable?: boolean,
  expandList: boolean,
}

export interface HistoryArticle extends Article {
    date: number;
}

// SDS
export interface SafetyDataSheet {
    id: string;
    header: ArticleDetailHeader;
    section_one: ArticleSectionOne;
    section_two: ArticleSectionTwo;
    section_three: ArticleSectionThree;
    section_four: ArticleSectionFour;
    section_five: ArticleSectionFive;
    section_six: ArticleSectionSix;
    section_seven: ArticleSectionSeven;
    section_eight: ArticleSectionEight;
    section_nine: ArticleSectionNine;
    section_ten: ArticleSectionTen;
    section_eleven: ArticleSectionEleven;
    section_twelve: ArticleSectionTwelve;
    section_thirteen: ArticleSectionThirteen;
    section_fourteen: any;
    section_fifteen: ArticleSectionFifteen;
    section_sixteen: any;
}

// SDS Header
export interface ArticleDetailHeader {
    articleName: string;
    articleNumber: string;
    logoCompany: string;
    reportVersion: string;
    revisionDate: string;
}

// SDS Section 1
export interface ArticleSectionOne {
    articleNumber: string;
    articleName: string;
    registrationNumber: string;
    registrationNumberSupplier: string;
    registrationOkay: string;
    reachInformation: string[];
    validityArea: string;
    cas: string;
    indexNumber: string;
    identifiedUses: string[];
    usedAdvisedAgainst: string;
}

// SDS Section 2
export interface ArticleSectionTwo {
    emergencySummary: string[];
    hazardClassification: string[];
    clasRemarks: string[];
    hazReactions: string[];
    regBases: string[];
    hazardPictograms: string[];
    hazardPictogramIcons: string[];
    signalWord: string[];
    preStatements: string[];
    hazardStatements: string[];
    hazardStatementsSec: string[];
    specRemarks: string[];
    specLabels: string[];
    specialPreps: string[];
    senses: string[];
    comments: string[];
    precautionaryStatements: PrecautionaryStatements;
    additionalRemarks: AdditionalRemarks;
    resultsPBTassessments: string[];
    remarks: string[];
    otherHazards: string[];
    otherAdvice: string[];
}

export interface PrecautionaryStatements {
    prevention: string[];
    response: string[];
    storage: string[];
    disposal: string[];
}

export interface AdditionalRemarks {
    physchemhaz: string;
    healthhaz: string;
    environhaz: string;
}

// SDS Section 3
export interface ArticleSectionThree {
    formula: string
    hill: string
    index: string
    egNumber: string
    molar: string
    compositions: Composition[]
}

export interface Composition {
    cas: string
    chemicalNumber: string
    egIndexNumber: string
    chemCharacteristic: string
    pbtAssessment: string
    registrationNumber: string
    registrationNumberSupplier: string
    remarks: string[]
    classifications: string[]
    limits: string
    mFactor: string
    avgValue: string
    lowLimit: string
    uppLimit: string
}

// SDS Section 4
export interface ArticleSectionFour {
    firstAidInfo: string[]
    firstAidInhalation: string[]
    firstAidSkin: string[]
    firstAidEye: string[]
    firstAidIngestion: string[]
    notesphysician: string[]
    symptoms: string[]
    remarks: string[]
    memos: string[]
    treatment: string[]
}

// SDS Section 5
export interface ArticleSectionFive {
    suitableMedia: string[]
    unsuitableMedia: string[]
    info: string[]
    specialHazards: string[]
    adviseFirefighters: AdviseFirefighters
}

export interface AdviseFirefighters {
    specialEquipment: string[]
    furtherInformation: string[]
}

// SDS Section 6
export interface ArticleSectionSix {
    personalPrecautions: string[];
    environmentalPrecautions: string[];
    containmentAndCleaningUp: string[];
}

// SDS Section 7
export interface ArticleSectionSeven {
    precautionsForSafeHandling: PrecautionsForSafeHandling;
    conditionsForSafeStorage: ConditionsForSafeStorage;
    specificEndUses: SpecificEndUses;
}

export interface PrecautionsForSafeHandling {
    safeHandling: string[];
    fireAndExplosionProtection: string[];
    temperatureClass: string[];
    firefightingClass: string[];
    hygieneMeasures: string[];
}

export interface ConditionsForSafeStorage {
    storageConditionsAreaAndContainer: string[];
    storageConditionsMerck: string[];
    storageConditionsGeneral: string[];
    commonStorageAdvice: string[];
    maxStorageDuration: string[];
    recommendedStorageTemperature: string[];
    otherData: string[];
    storageClass: string[];
}

export interface SpecificEndUses {
    specificEndUses: string[];
}

// SDS Section 8
export interface ArticleSectionEight {
    controlParameters: ControlParameters
    exposureControls: ExposureControls
}

export interface ControlParameters {
    exposureLimits: LimitItem[];
    dnelItems: string[];
    pnecItems: string[];
}

export interface LimitItem {
    name: string;
    cas: string;
    regbases: RegBase[];
}

export interface RegBase {
    regbase: string;
    valueTypes: ValueType[];
}

export interface ValueType {
    valueType: string;
    value: string[];
}

export interface ExposureControls {
    engineeringMeasures: string[];
    individualMeasures: IndividualMeasures;
    environmentalControls: string[];
}

export interface IndividualMeasures {
    hygiene: string;
    eyeFaceProt: string;
    handProtection: HandProtection;
    otherEquipments: string[];
    breathProtections: string[];
    filterTypes: string[];
}

export interface HandProtection {
    fullContact: FullContact;
    splashContact: SplashContact;
}

export interface FullContact {
    contactType: string;
    gloveMaterial: string;
    gloveThickness: string;
    breakThroughTime: string;
    manufacturer: string;
    gloveTested: string;
    additionalProtection: string;
}

export interface SplashContact {
    splashContactType: string;
    splashGloveMaterial: string;
    splashGloveThickness: string;
    splashBreakThroughTime: string;
    splashManufacturer: string;
    splashGloveTested: string;
    splashAdditionalProtection: string;
}

// SDS Section 9
export interface ArticleSectionNine {
    basicPhysChemProperties: BasicPhysChemProperties;
    otherData: OtherData;
}
export interface BasicPhysChemProperties {
    form: string[];
    colour: string[];
    odour: string[];
    odourThreshold: string[];
    pH: string[];
    meltingPoint: string[];
    boilingPoint: string[];
    flashPoint: string[];
    evaporationRate: string[];
    flammability: string[];
    lowerExplosionLimit: string[];
    upperExplosionLimit: string[];
    vapourPressure: string[];
    relativeVapourDensity: string[];
    density: string[];
    solubility: string[];
    waterSolubility: string[];
    partitionCoefficient: string[];
    autoignitionTemperature: string[];
    decompositionTemperature: string[];
    dynamicViscosity: string[];
    explosiveProperties: string[];
    oxidizingProperties: string[];
    peroxides: string[];
}

export interface OtherData {
    caption: string[];
    typeValue: string[];
    ignitionTemperature: string[];
    minimumIgnitionEnergy: string[];
    dustExplosionConstant: string[];
    saturatedVapourConcentration: string[];
    bulkDensity: string[];
    particleSize: string[];
    poreDiameter: string[];
    kinematicViscosity: string[];
    etchrate: string[];
    corrosion: string[];
    conductivity: string[];
    refractiveIndex: string[];
    dielectricConstant: string[];
    dipoleMoment: string[];
    otherSolubility: string[];
    surfaceTension: string[];
    dissociationConstant: string[];
    burningRate: string[];
    radioactivity: string[];
    oxidationReductionPotential: string[];
}

// SDS Section 10
export interface ArticleSectionTen {
    reactivity: string[];
    reactionWith: string[];
    chemicalStability: string[];
    hazardReactions: string[];
    methods: string[];
    glp: string[];
    furtherInfo: string[];
    conditionsAvoid: string[];
    values: string[];
    units: string[];
    remarks: string[];
    incompatibleMaterials: string[];
    hazardousDecompositionProducts: string[];
    types: string[];
}

// SDS Section 11
export interface ArticleSectionEleven {
    toxicologicalEffects: ToxicologicalEffects;
    furtherInformation: FurtherInformation;
}

export interface ToxicologicalEffects {
    oralToxicity: string[];
    inhalationToxicity: string[];
    skinToxicity: string[];
    otherToxicity: string[];
    skinIrritation: string[];
    eyeIrritation: string[];
    sensitization: string[];
    cellMutagenicity: string[];
    genotoxicityVivo: string[];
    genotoxicityVitro: string[];
    cancerogenicity: string[];
    reproductiveToxicity: string[];
    cmrEffects: string[];
    mutagenicity: string[];
    teratogenicity: string[];
    specificOrganToxicitySingle: string[];
    specificOrganToxicityRepeated: string[];
    repeatedDoseToxicity: string[];
    aspirationToxicity: string[];
}

export interface FurtherInformation {
    furtherInfo: string[];
    symptoms: string[];
    otherInfo: string[];
    generalToxicity: string[];
    inhalationToxicity: string[];
    skinToxicity: string[];
    eyeIrritation: string[];
    oralToxicity: string[];
}

// SDS Section 12
export interface ArticleSectionTwelve {
    ecotoxicity: Ecotoxicity;
    persistenceAndDegradability: PersistenceAndDegradability;
    bioaccumulativePotential: BioaccumulativePotential;
    distribution: Distribution;
    pbt: Pbt;
    henry: Henry;
}

export interface Ecotoxicity {
    secTwelveToxicityFish: string[];
    secTwelveToxicityDaphnia: string[];
    secTwelveToxicityAlgae: string[];
    secTwelveToxicityBacteria: string[];
    secTwelveToxicityFishChronic: string[];
    secTwelveToxicityDaphniaChronic: string[];
}

export interface PersistenceAndDegradability {
    biodegradability: string[];
    biochemicalOxygenDemand: string[];
    chemicalOxygenDemand: string[];
    theoreticalOxygenDemand: string[];
    ratioBodCod: string[];
    ratioBodThbod: string[];
    ratioCodThbod: string[];
    dissolvedOrganicCarbon: string[];
    volatileOrganicCompounds: string[];
    adsorbedOrgBoundHalogen: string[];
    additionalInformationEnvironment: string[];
}

export interface BioaccumulativePotential {
    mfactor: string[];
    bioaccumulativePotential: string[];
    bioaccumulativity: string[];
    mFactor: string[];
}

export interface Distribution {
    distributionEnvironmentalCompartments: string[];
    mobility: string[];
}

export interface Pbt {
    pbtAssessment: string[];
}

export interface Henry {
    henryConstant: string[];
    surfaceTension: string[];
    stabilityWater: string[];
    otherEcologicalInformation: string[];
}

// SDS Section 13
export interface ArticleSectionThirteen {
    wasteTreatmentMethodsProduct:   string[];
    wasteTreatmentMethodsPackaging: string[];
    wasteControlAct:                string[];
}

// SDS Section 15
export interface ArticleSectionFifteen {
    accidentHazards: string[];
    euRegulation: EuRegulation;
    occupationalRestrictions: string[];
    storageClassVCI: string[];
    waterPollution: string[];
    dataSheetNumbers: string[];
    germanExplosives: GermanExplosives;
    dustExplosionClasses: string[];
    occupationalIllnesses: OccupationalIllnesses;
    regulatoryBasisRemarks: string[];
    chemicalSafetyAssessments: string[];
}

export interface EuRegulation {
    regBases: string;
    regList: string;
    revision: string;
    valueType: string;
    valueNSreg: string;
    attribute: string[];
}

export interface GermanExplosives {
    sprengG: string;
    subClass: string;
    storeGr: string;
}

export interface OccupationalIllnesses {
    regulationOcc: string;
    revisionOcc: string;
    numberOcc: string;
    remarksOcc: string;
}

export interface ScannedArticleDetails {
    article: Article;
    sds: SafetyDataSheet;
}

export class Settings implements ISettings {

    appLanguage = environment.defaultLanguage;

    home = { barcodeScanner: true };

    articleDetails = {
        safetyDataSheet: true,
        ehs: true,
        transportInformation: true
    };
    dataPrivacy = {
        favourites: true,
        customerData: true
    };
    sections = [
        true, /*section 1*/
        true, /*section 2*/
        true, /*section 3*/
        true, /*section 4*/
        true, /*section 5*/
        true, /*section 6*/
        true, /*section 7*/
        true, /*section 8*/
        true, /*section 9*/
        true, /*section 10*/
        true, /*section 11*/
        true, /*section 12*/
        true, /*section 13*/
        false, /*section 14*/
        true, /*section 15*/
        false /*section 16*/
    ];
    location: Location = LocationService.getDefaultLocation();

    validityAreaLanguage = {
        rating: environment.rating,
        validityArea: environment.validityArea,
        language: environment.language
    }
}

export interface ISettings {
    appLanguage?: string,
    home: Home;
    articleDetails: ArticleDetails; // FIXME not used, can be deleted?
    dataPrivacy: DataPrivacy; // FIXME not used, can be deleted?
    sections: boolean[];
    location: Location;
    validityAreaLanguage: ValidityAreaLanguage;
}

export interface Home {
    barcodeScanner: boolean;
}

export interface ArticleDetails {
    safetyDataSheet: boolean;
    ehs: boolean;
    transportInformation: boolean;
}

export interface DataPrivacy {
    favourites: boolean;
    customerData: boolean;
}

export interface Location {
    error: number;
    lat: number;
    long: number;
    countryCode: string;
    city: string;
    askLocationAgain: boolean;
    locationUpdate: boolean;
}

export interface ValidityAreaLanguage {
    rating: string;
    validityArea: string;
    language: string;
}

export interface ValidityAreaLanguages {
    rating: string;
    validityArea: string;
    languages: string[];
}

export interface ArticleUpdateFields {
    template?: string;
    amount?: string;
    fillType?: string;
    materialDate?: any;
    extraText?: string;
    canvas?: string;
    ngClass?: string;
    rotated_by?: number;
    rotateByClass?: string;
}

export interface MaterialPictogram {
    material: string,
    pictoLength: number
}