import { StorageMigration } from "./storage-migration";

export class StorageMigrationOptions {
    /**
     * Latest storage version.
     */
    version: number;

    /**
     * Key for the version. Defaults to 'version'.
     */
    versionKey: string;

    migrations?: StorageMigration[];
}