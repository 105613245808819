import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Article, FavoritesList } from '../../types/types';
import { BehaviorSubject } from 'rxjs';
import { DialogService } from "src/app/services/dialog.service";
import { FavoriteStoreService } from "src/app/services/favorite-storage.service";
import { ModalController } from '@ionic/angular';
import { FavouritesService } from "src/app/services/favourites.service";
import { Console } from "console";
import { FavoritesListsPage } from "src/app/favorites-lists/favorites-lists";


@Component({
  selector: "DeleteAlertPopup",
  templateUrl: "DeleteAlertPopup.html",
  styleUrls: ['./DeleteAlertPopup.scss'],
})
export class DeleteAlertPopup{
  @Input() favSelectedValue : string;
  @Input() onDismiss: () => void;
  @Input() oldName:any;
  @Input() pgName:string;
  @Input() event: any;
  currentName: string;
  public myTextLen = 0;
  public showAll:boolean = true;
  isModalOpen = false;
  expandListState:FavoritesList[];
  private favoritesLists$: BehaviorSubject<FavoritesList[]> = new BehaviorSubject([]);
  favoritesLists = this.favoritesLists$.asObservable();

  private favourites$: BehaviorSubject<Article[]> = new BehaviorSubject([]);
  favourites = this.favourites$.asObservable();
  public favCount: number;
  inputModel: string;
  
    constructor(
        private favouriteService: FavouritesService,
        private dialogService: DialogService,
        private modalCtrl: ModalController,
        private favoritesService: FavouritesService,
        private favoritesListsPage: FavoritesListsPage
        
    ) {
        this.favoritesService.favoritesLists$.subscribe(favoritesLists => this.favCount = favoritesLists.length)
    }


  ngOnInit(){
    if(this.pgName!="popover" &&  this.pgName!="fav-list-item"){
        this.showAll = false
    }
    this.inputModel = this.oldName;
  }

 
  closeWin() {
    this.modalCtrl.dismiss()
    
  }

  async onDeleteClick(data){
    if(this.pgName=="popover") 
    {
            this.favouriteService.removeFavoritesList(data);
            this.modalCtrl.dismiss();
            this.dialogService.notify('LIST_DELETED_SUCCESS');

        }else if(this.pgName=="fav-list-item"){
          await this.favoritesService.removeFavoriteFrom(this.event.article, this.event.favList.name);
            this.modalCtrl.dismiss();
        }else{
            this.favoritesService.clearFavourites()
            this.modalCtrl.dismiss();
        }
    }
}
