import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Article, FavoritesList } from '../../types/types';
import { BehaviorSubject } from 'rxjs';
import { DialogService } from "src/app/services/dialog.service";
import { FavoriteStoreService } from "src/app/services/favorite-storage.service";
import { ModalController } from '@ionic/angular';
import { FavouritesService } from "src/app/services/favourites.service";
import { Console } from "console";
import { AddListAlertPopup } from "../AddListAlertPopup/AddListAlertPopup";


@Component({
  selector: "InfocategoryEmptyListPopup",
  templateUrl: "InfocategoryEmptyListPopup.html",
  styleUrls: ['./InfocategoryEmptyListPopup.scss'],
})
export class InfocategoryEmptyListPopup{
  @Input() favSelectedValue : string;
  @Input() onDismiss: () => void;
  @Input() oldName:any;
  @Input() pgName:string;
  @Input() article: Article;
  currentName: string;
  public myTextLen = 0;
  public showAll:boolean = true;
  isModalOpen = false;
  expandListState:FavoritesList[];
  private favoritesLists$: BehaviorSubject<FavoritesList[]> = new BehaviorSubject([]);
  favoritesLists = this.favoritesLists$.asObservable();

  private favourites$: BehaviorSubject<Article[]> = new BehaviorSubject([]);
  favourites = this.favourites$.asObservable();
  public favCount: number;
  inputModel: string;
  
    constructor(
        private favouriteService: FavouritesService,
        private dialogService: DialogService,
        private modalCtrl: ModalController,
        private favoritesService: FavouritesService,
        
    ) {
        this.favoritesService.favoritesLists$.subscribe(favoritesLists => this.favCount = favoritesLists.length)
    }


  ngOnInit(){

  }

 
  closeWin() {
    this.modalCtrl.dismiss()
    
  }
  public async addListPopup(){
    this.modalCtrl.dismiss()
    const modal = await this.modalCtrl.create({
      component: AddListAlertPopup,
      cssClass  : 'add-list-alert-popup',
      componentProps: {
        article: this.article
      }      
    });
    return await modal.present();

  }

}
