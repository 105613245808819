import { Injectable } from '@angular/core';

import { DialogService } from './dialog.service';
import { SettingsService } from './settings.service';
import { TranslateService } from '@ngx-translate/core';
import { ISettings } from '../types/types';


@Injectable({
    providedIn: 'root',
})
export class EmergencyService {

    settings: ISettings;

    constructor(public dialogService: DialogService,
        public settingsService: SettingsService,
        public translate: TranslateService) {
        this.settingsService.settings.subscribe((settings) => this.settings = settings);
    }

    public emergencyNumbers: any = {
        // Africa
        'ZA': '0800983611',
        // Americas
        'AR': '00541159839431',
        'BR': '00552139581449',
        'KY': '0013457498392',
        'CL': '0056225814934',
        'CO': '018007102151',
        'CR': '0050640003869',
        'MX': '018006819531',
        'PA': '005078322475',
        'PE': '005117071295',
        'TT': '0018682245716',
        // Asia
        'BH': '0097316199372',
        'HK': '800968793',
        'IN': '0008001007141',
        'IN Mumbai': '00912233543594',
        'ID': '0018030179114',
        'IL': '0097237630639',
        'JP': '0081345209637',
        'MY Kuala Lumpur': '0060327884561',
        'MY': '1800815308',
        'PH': '180011161020',
        'PH Manila': '006323953308',
        'RU': '88001006346',
        'SA': '009668111095861',
        'SG': '8001012201',
        'KR': '00308132549',
        'TW': '00801148954',
        'TH': '001800132039987',
        'TR': '00902127055340',
        'VN': '0084444581771',
        // Oceania
        'AU': '0061290372994',
        'NZ': '006498010034',
        // Europe
        'AT': '004313649237',
        'BE': '003228083237',
        'BG': '0035932570104',
        'HR': '0038517776920',
        'CZ': '00420228880039',
        'DK': '004569918573',
        'FI': '00358942419014',
        'FR': '0033975181407',
        'DE Frankfurt': '004969643508409',
        'DE': '08001817059',
        'GR': '00302111768478',
        'HU': '003618088425',
        'IE': '0035319014670',
        'IT': '800789767',
        'IT Milan': '00390245557031',
        'LV': '0037166165504',
        'LT': '0037052140238',
        'LU': '0035220202416',
        'NL': '0031858880596',
        'NO': '004721930678',
        'PL': '0048223988029',
        'PT': '00351308801773',
        'SK': '00421233057972',
        'SI': '0038618888016',
        'ES Barcelona': '0034931768545',
        'ES': '900868538',
        'SE': '0046852503403',
        'CH': '0041435082011',
        'UA': '00380947101374',
        'UK': '00448708200418'
    };

    public getEmergencyNumbers(): any {
        return this.emergencyNumbers;
    }

    public getCurrentNumber(): Promise<any> {
        return new Promise((resolve, reject) => {
            // Load from settings

            // Case: no country set
            if (this.settings.location.error > 0)
                this.dialogService.notify(this.translate.instant('EMERGENCY_NOTIFY_NO_COUNTRY'));

            let response = {
                number: null,
                countryCode: this.settings.validityAreaLanguage.validityArea,
                city: this.settings.location.city
            };

            // Special cases (specific city)
            if (response.city == 'Mumbai')
                response.number = this.emergencyNumbers['IN Mumbai'];
            else if (response.city == 'Kuala Lumpur')
                response.number = this.emergencyNumbers['MY Kuala Lumpur'];
            else if (response.city == 'Manila')
                response.number = this.emergencyNumbers['PH Manila'];
            else if (response.city == 'Frankfurt')
                response.number = this.emergencyNumbers['DE Frankfurt'];
            else if (response.city == 'Milan')
                response.number = this.emergencyNumbers['IT Milan'];
            else if (response.city == 'Barcelona')
                response.number = this.emergencyNumbers['ES Barcelona'];
            else response.number = this.emergencyNumbers[response.countryCode];

            resolve(response);
        });
    }

}
