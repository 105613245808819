import { Injectable } from '@angular/core';

/*
  Generated class for the CypressProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root',
})
export class Cypress {

  constructor() {

  }

  isRunning(){
      //noinspection TypeScriptUnresolvedVariable
      // return !!(window as CostumWindow).Cypress;
  }

  getOrientation(){
    return window.innerHeight > window.innerWidth? 'portrait' : 'landscape';
  }

}

interface CypressWindow {
  Cypress:any;
}

// class CostumWindow extends Window implements CypressWindow{
//     Cypress: any;

// }
