import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  isLoading = false;
  private mobileBrowserName: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public mobileBrowserName$ = this.mobileBrowserName.asObservable();

  constructor(public loadingController: LoadingController) {
      const userAgent = window.navigator.userAgent.toLowerCase();
      const checkMobileBrowser = userAgent.includes('mobile');
      const platforms = ['android', 'iphone', 'ipad'];
      if(checkMobileBrowser) {
        for (let platform of platforms) {
          if (userAgent.includes(platform)) {
            this.mobileBrowserName.next(platform);
            break;
          }
        }
      }
   }

  async present() {
    this.isLoading = true;
    return await this.loadingController.create({
      // duration: 5000,
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  async dismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  }
}