
import { NgModule, ErrorHandler } from '@angular/core';
import {GlobalErrorHandler} from './global-error-handle';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { SocialSharing } from '@ionic-native/social-sharing/ngx/';
import { Dialogs } from '@ionic-native/dialogs/ngx';
import { Network } from '@ionic-native/network/ngx';
import { DatePicker } from '@ionic-native/date-picker/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { Globalization } from '@ionic-native/globalization/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx/';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { AppRate } from '@awesome-cordova-plugins/app-rate';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Toast } from '@ionic-native/toast/ngx/';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx/';
import { LoggingInterceptor } from './services/logging/logging.interceptor';
import { AppComponent } from './app.component';
import { LoadingService } from './services/loading.services';
import { Cypress } from './services/cypress/cypress';
import { AuthInterceptor } from './services/auth.interceptor';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from './services/translate.service';
import { StorageService } from './services/storage.service';
import { HttpService } from './services/http.service';
import { SafetyDataAPI } from './services/safety-data-api.service';
import { FavoriteStoreService } from './services/favorite-storage.service';
import { DialogService } from './services/dialog.service';
import { ErrorService } from './services/error.service';
import { FavouritesService } from './services/favourites.service';
import { HistoryService } from './services/history.service';
import { AuthentService } from './services/authent.service';
import { SDSService } from './services/sds.service';
import { DeleteAccountService } from './services/deleteAccount.service';
import { TokenService } from './services/token.service';
import { LoadingProvider } from './services/loading/loading';
import { SettingsService } from './services/settings.service';
import { EmergencyService } from './services/emergency.service';
import { LocationService } from './services/location.service';
import { ExternalLinkService } from './services/external-link.service';
import { HazardPictogramProvider } from './services/hazard-pictogram/hazard-pictogram';
import { GridProvider } from './services/grid/grid';
import { LabelScannerService } from './services/label-scanner/label-scanner.service';
import { BarcodeGeneratorService } from './services/barcode-generator/barcode-generator.service';
import { UtilProvider } from './services/util/util';
import { StorageMigrationModule } from './services/storage-migration/storage-migration.module';
import { ConnectivityProvider } from './services/connectivity/connectivity';
import { ValidityAreaLanguagesProvider } from './services/validity-area-languages/validity-area-languages';
import { environment } from './env/env';
import { StorageMigrationOptions } from './services/storage-migration/storage-migration-options';
import { migrations } from './services/storage-migration/migrations';
import { ToastProvider } from './services/toast/toast';
import { DataSyncService } from './services/data-sync.service';
import { IndeterminateProgressComponent } from './components/indeterminate-progress/indeterminate-progress';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { Device } from '@ionic-native/device/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { ToastrModule } from 'ngx-toastr';
import {TagsService} from './services/tags.service';
import { HeaderMenuComponent } from './components/header-menu/header-menu';
import { SearchResultItemComponent } from './components/search-result-item/search-result-item';
import { FooterMenuComponent } from './components/footer-menu/footer-menu';
import { PopoverComponent } from './components/popoverone/popover.component';

export function storageMigrationFactory(): StorageMigrationOptions {
  return {
      version: environment.storageVersion,
      versionKey: 'version',
      migrations: migrations
  };
}


@NgModule({
  declarations: [AppComponent, IndeterminateProgressComponent,HeaderMenuComponent,FooterMenuComponent, PopoverComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      defaultLanguage: environment.defaultLanguage,
      loader: {
          provide: TranslateLoader,
          useFactory: (HttpLoaderFactory),
          deps: [HttpClient]
      }
    }),
    StorageMigrationModule.forRoot({
        storageMigrationOptions: {
            provide: StorageMigrationOptions,
            useFactory: storageMigrationFactory
        }
    }),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
    FormsModule
  ],
  providers: [
        StatusBar,
        // SplashScreen,
        OpenNativeSettings,
        Device,
        AppVersion,
        InAppBrowser,
        EmailComposer,
        HttpService,
        TokenService,
        SettingsService,
        DatePicker,
        StorageService,
        DialogService,
        TagsService,
        ErrorService,
        AuthentService,
        DeleteAccountService,
        FavouritesService,
        HistoryService,
        BarcodeScanner,
        SocialSharing,
        ScreenOrientation,
        Globalization,
        Network,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        SDSService,
        EmergencyService,
        LoadingService,
        NativeGeocoder,
        LocationService,
        LabelScannerService,
        BarcodeGeneratorService,
        GridProvider,
        NativeStorage,
        HazardPictogramProvider,
        LoadingProvider,
        ExternalLinkService,
        SafariViewController,
        Cypress,
        UtilProvider,
        FavoriteStoreService,
        ConnectivityProvider,
        Toast,
        ValidityAreaLanguagesProvider,
        ToastProvider,
        Dialogs,
        SafetyDataAPI,
        Deeplinks,
        SignInWithApple,
        DataSyncService,
        DeviceDetectorService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        
        environment.validityArea==='CN'?{
          provide:LocationStrategy,
          useClass:HashLocationStrategy
        }:[]
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
