import { Injectable } from '@angular/core';

export interface HazardPictogram {
    ghsCode: string;
    image: string;
}

@Injectable({
    providedIn: 'root',
})
export class HazardPictogramProvider {

    private ALL_HAZARD_PICTOGRAMS: HazardPictogram[] =
        [this.explodingBomb, this.flame, this.flameOverCircle,
            this.gasCylinder, this.corrosion, this.skullAndCrossbones,
            this.exclamationMark, this.healthHazard, this.environment];

    constructor() {
    }


    getPictogramIcons(hazardPictograms: string[]): Array<string> {
        const pictograms: Array<string> = [];

        hazardPictograms.forEach(hazardPictogram => {
            let symbol = this.mapSymbol(hazardPictogram);
            if (symbol) {
                pictograms.push(symbol.image);
            }
        });

        return pictograms;
    }

    private mapSymbol(hazardPictogramCode: string): HazardPictogram {
        return this.ALL_HAZARD_PICTOGRAMS.find(symbol =>
            symbol.ghsCode == hazardPictogramCode);
    }

    get explodingBomb(): HazardPictogram {
        return {
            image: 'assets/pictograms/GHS01.png',
            ghsCode: 'GHS01'
        };
    }

    get flame(): HazardPictogram {
        return {
            image: 'assets/pictograms/GHS02.png',
            ghsCode: 'GHS02'
        };
    }

    get flameOverCircle(): HazardPictogram {
        return {
            image: 'assets/pictograms/GHS03.png',
            ghsCode: 'GHS03'
        };
    }

    get gasCylinder(): HazardPictogram {
        return {
            image: 'assets/pictograms/GHS04.png',
            ghsCode: 'GHS04'
        };
    }

    get corrosion(): HazardPictogram {
        return {
            image: 'assets/pictograms/GHS05.png',
            ghsCode: 'GHS05'
        };
    }

    get skullAndCrossbones(): HazardPictogram {
        return {
            image: 'assets/pictograms/GHS06.png',
            ghsCode: 'GHS06'
        };
    }

    get exclamationMark(): HazardPictogram {
        return {
            image: 'assets/pictograms/GHS07.png',
            ghsCode: 'GHS07'
        };
    }

    get healthHazard(): HazardPictogram {
        return {
            image: 'assets/pictograms/GHS08.png',
            ghsCode: 'GHS08'
        };
    }

    get environment(): HazardPictogram {
        return {
            image: 'assets/pictograms/GHS09.png',
            ghsCode: 'GHS09'
        };
    }


}
