import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Article, ArticleUpdateFields, MaterialPictogram } from '../types/types';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  public articles: BehaviorSubject<Article[]> = new BehaviorSubject([]);
  public articles$ = this.articles.asObservable();
  public materialArr:MaterialPictogram[] = [];
  public readDateOnce="";

  constructor() {}

  public setArticles(articles: Article[]) {
    this.articles.next(articles);
  }

  public setMaterial(material: string, pictoLength: number) {
    const matPic: MaterialPictogram = {
      material,
      pictoLength
    }
    this.materialArr.push(matPic);
  }

  public updateArticlesInput(info: ArticleUpdateFields) {
    let tempArticles = [];
    this.articles.forEach((list) => {
      tempArticles = list;
    });
    tempArticles = tempArticles.map((article: Article) => {
      return {...article, ...info};
    });
    this.articles.next(tempArticles);
  }

}