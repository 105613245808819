import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx';


@Injectable({
    providedIn: 'root',
})
export class ExternalLinkService {
    constructor(private platform: Platform,
                private safariViewController: SafariViewController) {
    }

    openExternalUrl(u: string, openInSystemBrowser?: string) {
        let url = encodeURI(u);
        console.log('ExternalLinkService: openExternalUrl() called with url: ' + url);

        if (!url.startsWith('http')) {
            url = 'http://' + url;
        }

        // we are in a browser
        if (!this.platform.is('cordova')) {
            window.open(url, '_blank');
        } else if (openInSystemBrowser) {
            // we are using a device and url should be opened in system browser, not in-app
            window.open(url, '_system', 'location=yes');
        } else {
            // we are using a device and url should be opened in "In-App-Browser"
            this.safariViewController.isAvailable()
                .then((available: boolean) => {
                    // iOS >=9
                    if (available) {
                        this.safariViewController.show({
                            /* tslint:disable:ter-max-len */
                            url: url,
                            hidden: false, // default false. You can use this to load cookies etc in the background (see issue #1 for details).
                            animated: true, // default true, note that 'hide' will reuse this preference (the 'Done' button will always animate though)
                            transition: 'fade', // (this only works in iOS 9.1/9.2 and lower) unless animated is false you can choose from: curl, flip, fade, slide (default)
                            enterReaderModeIfAvailable: false, // default false
                            showDefaultShareMenuItem: true,
                            //barColor: '#ff0000', // iOS toolbar color
                            //tintColor: '#ff0000', // iOS, default is ios blue
                            toolbarColor: '#29B8CD' // Android, on iOS 10+ you can change the background color as well
                            //controlTintColor: '#ffffff' // iOS, on iOS 10+ you can override the default tintColor
                            /* tslint:enable:ter-max-len */
                        })
                            .subscribe((result) => {
                                    if (result.event === 'opened') {
                                        console.log('Opened');
                                    } else if (result.event === 'loaded') {
                                        console.log('Loaded');
                                    } else if (result.event === 'closed') {
                                        console.log('Closed');
                                    }
                                },
                                (error: any) => console.error(error)
                            );
                    } else {
                        // iOS < 9 or any other device
                        // use fallback browser, example InAppBrowser
                        //this.inAppBrowser.create(item.link);

                        // potentially powered by InAppBrowser because that (currently) clobbers window.open
                        window.open(url, '_system', 'location=yes');
                    }
                })
                .catch(error => {
                    console.error('Uhhh, error checking if SafariViewController is available');
                    console.error(error);
                });
        }
    }


}
