import { ErrorHandler, Injectable } from '@angular/core';
import {Capacitor} from '@capacitor/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  
    handleError(err: any): void {

      if(Capacitor.getPlatform() == 'web') {
        const chunkFailedMessage = /Loading chunk/;
        if (chunkFailedMessage.test(err.message)) {
          window.location.reload();
        }
      }
  }
}