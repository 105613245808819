import { Component, Input } from "@angular/core";
import { ModalController } from '@ionic/angular';
import { SettingsService } from "src/app/services/settings.service";

@Component({
  selector: "UpdateLocationPopup",
  templateUrl: "UpdateLocationPopup.html",
  styleUrls: [],
})
export class UpdateLocationPopup{
 
  @Input() onDone: (response: { button: boolean, input: boolean }) => void;

  inputCheckbox: boolean = false;
  
  constructor(
    private modalCtrl: ModalController,
  ) {
  }

  public closeWin() {
    this.modalCtrl.dismiss();
  }

  public onChangeCheckbox() {
    this.inputCheckbox = !this.inputCheckbox;
  }

  public async updateLocation(button: boolean) {
    this.onDone({
      button,
      input: this.inputCheckbox,
    })
    this.modalCtrl.dismiss();
  }
}
