import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { StorageMigrationOptions } from './storage-migration-options';
import { environment } from '../../env/env';

@Injectable({
    providedIn: 'root',
})
export class StorageMigrationService {

    private currentVersion: number;

    constructor(private storage: Storage,
        private options: StorageMigrationOptions) {

    }

    async migrate() {
        this.currentVersion = await this.loadCurrentVersion();
        if (this.currentVersion === environment.storageVersion) {
            return;
        }

        // TODO sort migrations by version
        // cannot use forEach with await
        for (const strategy of this.options.migrations) {
            if (strategy.from === this.currentVersion && strategy.to <= this.options.version) {
                this.currentVersion = await strategy.migrate(this.storage)
            }
        }

        return await this.saveCurrentVersion(this.currentVersion);
    }

    private loadCurrentVersion(): Promise<number> {
        return this.storage.get(this.options.versionKey);
    }

    private saveCurrentVersion(currentVersion: number): Promise<any> {
        return this.storage.set(this.options.versionKey, currentVersion);
    }
}