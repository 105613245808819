import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Article, FavoritesList } from '../../types/types';
import { BehaviorSubject } from 'rxjs';
import { DialogService } from "src/app/services/dialog.service";
import { FavoriteStoreService } from "src/app/services/favorite-storage.service";
import { ModalController } from '@ionic/angular';
import { FavouritesService } from "src/app/services/favourites.service";


@Component({
  selector: "EditListAlertPopup",
  templateUrl: "EditListAlertPopup.html",
  styleUrls: ['./EditListAlertPopup.scss'],
})
export class EditListAlertPopup{
  @Input() oldName:any;
  @Input() favSelectedValue : string;
  @Input() onDismiss: () => void;
  currentName: string;
  public myTextLen = 0;
  isModalOpen = false;
  expandListState:FavoritesList[];
  private favoritesLists$: BehaviorSubject<FavoritesList[]> = new BehaviorSubject([]);
  favoritesLists = this.favoritesLists$.asObservable();

  private favourites$: BehaviorSubject<Article[]> = new BehaviorSubject([]);
  favourites = this.favourites$.asObservable();
  
  inputModel: string;
  
    constructor(
      private favouriteService: FavouritesService,
        private dialogService: DialogService,
        private modalCtrl: ModalController
   
    ) {
  }

  ngOnInit(){
    this.inputModel = this.oldName;
    this.myTextLen = this.inputModel.length;
  }

  countChar(e) {
    if(e.trim()==""){
      this.inputModel = ""
      e=""
    }
    
    this.myTextLen = e.length;
  }

  closeWin() {
    this.modalCtrl.dismiss()   
  }

  async onEditSaveClick(data){
    let newName = data;
    let response = await this.favouriteService.updateFavoritesList(this.oldName, newName);
    if (response.success) {
      this.dialogService.notify('FAVORITES_LIST_EDIT_SUCCESS');
      this.closeWin();
      this.onDismiss();
    } else if (response.error) {
      this.dialogService.notify(response.error);
    } else {
      this.dialogService.notify('FAVORITES_LIST_EDIT_FAIL');
    }
  }
}
