import { StorageMigration } from './storage-migration';
import { Storage } from '@ionic/storage';
import { storageKeys } from '../storage-keys';
import { ISettings, Settings } from '../../types/types';

export class MigrationNullTo1 extends StorageMigration {

    constructor() {
        super(null, 1);
    }

    async internalMigrate(storage: Storage) {
        const oldSettings = await this.getSettings(storage);
        const newSettings = new Settings();
        if (oldSettings) {
            newSettings.home = oldSettings.home;
            newSettings.sections = oldSettings.sections;
            newSettings.dataPrivacy = oldSettings.dataPrivacy;
            newSettings.location = oldSettings.location;
            newSettings.articleDetails = oldSettings.articleDetails;
        }

        await this.updateSettings(storage, newSettings);
    }

    private updateSettings(storage: Storage, settings: ISettings): Promise<any> {
        return storage.set(storageKeys.settings, settings);
    }

    private getSettings(storage: Storage): Promise<ISettings> {
        return storage.get(storageKeys.settings);
    }
}
