import { Injectable } from '@angular/core';
import { NativeGeocoder, NativeGeocoderResult } from '@ionic-native/native-geocoder/ngx';
import { DialogService } from './dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '../types/types';
import { environment } from '../env/env';
import { Platform } from '@ionic/angular';
// import { Capacitor } from '@capacitor/core';
import {Geolocation} from '@capacitor/geolocation'
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class LocationService {

    private geoOptions = {
        maximumAge: 300000,
        timeout: 15000,
        enableHighAccuracy: false
    };

    private currentLocation = LocationService.getDefaultLocation();

    constructor(public nativeGeocoding: NativeGeocoder,
        public dialogService: DialogService,
        private httpService: HttpClient,
        public platform: Platform,
        public translate: TranslateService) {

    }

    public getCurrentLocation(): Promise<Location> {
        return new Promise((resolve, reject) => {
            Geolocation.getCurrentPosition(this.geoOptions).then((geo) => {
                this.currentLocation.lat = geo.coords.latitude;
                this.currentLocation.long = geo.coords.longitude;

                // Get Google Maps API data
                this.nativeGeocoding.reverseGeocode(this.currentLocation.lat, this.currentLocation.long)
                    .then((geocode: NativeGeocoderResult[]) => {
                        // Get country code
                        this.currentLocation.countryCode = geocode[0].countryCode;
                        this.currentLocation.city = geocode[0].administrativeArea;
                        this.currentLocation.error = -1; // No error
                        resolve(this.currentLocation);
                    })
                    .catch((error) => {
                        reject(error.toString());
                    });
            }, error => {
                reject(String(error));
            });
        });
    }

    public getCountry(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpService.get(`${environment.safetyDataAPIBaseURL}/user/country`).subscribe(res => {
                resolve(res);
            }, error => {
                reject('Error');
            });
        });
    }

    public static getDefaultLocation(): Location {
        return {
            error: 0,
            lat: null,
            long: null,
            countryCode: environment.validityArea,
            city: null,
            askLocationAgain: true,
            locationUpdate: false
        };
    }
}
