import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { AuthentService } from "src/app/services/authent.service";


@Component({
  selector: "logout-popover",
  templateUrl: "logout-popover.html",
  styleUrls: ['./logout-popover.scss'],
})
export class LogoutPopoverComponent {
  @Input() loginuser: any;
  @Input() onLogout: () => void;

  public xyz : any;
  constructor(private router: Router,
    public authentService: AuthentService,
    ) {
  }

  async onClickLogout(){
    this.onLogout();
    this.loginuser = localStorage.setItem("loginUser", "");
    await this.authentService.logout();
    this.router.navigateByUrl("/", { replaceUrl: true });
    setTimeout(() => this.router.navigateByUrl("/login", { replaceUrl: true }), 100)
    setTimeout(() => window.location.reload(), 200)
  }
}
