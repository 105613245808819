import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SettingsService } from 'src/app/services/settings.service';
import { environment } from '../../env/env';
import { ExternalLinkService } from '../../services/external-link.service';

@Component({
  selector: 'mQuantStripModal',
  templateUrl: './mQuantStripModal.html',
  styleUrls: ['./mQuantStripModal.scss'],
})
export class MQuantStripModal  {

  constructor(
    private modalCtrl: ModalController,
    public external: ExternalLinkService,
    private settingsService: SettingsService,
  ) { 
  }
  
  closeModal() {
    this.modalCtrl.dismiss();
  }

  public navigateToPlayStore () {
    if (environment.region == 'ROW') {
        this.external.openExternalUrl('https://play.google.com/store/apps/details?id=de.merck.mquant.stripscan.googleplay');
    } else {
        this.external.openExternalUrl('https://play.google.com/store/apps/details?id=de.merck.mquant.stripscan.googleplay');
    }
}

public navigateToAppleStore () {
    let country = environment.validityArea.toLowerCase();
    let settingUnsubscribe = this.settingsService.settings.subscribe((settingInfo) => {
        country = settingInfo.validityAreaLanguage.validityArea.toLowerCase();
    });
    let url = "https://apps.apple.com/us/app/id1362147895";
    if (environment.region == 'ROW') {
        url = `https://apps.apple.com/us/app/id1362147895`;
    }
    settingUnsubscribe.unsubscribe();
    this.external.openExternalUrl(url);
}

}
