export const storageKeys = {
    favoritesLists: 'favorites_lists',
    history: 'history',
    gridFrequencies: 'FREQUENCIES',
    validityAreaLanguages: 'validityAreaLanguages',
    settings: 'settings',
    welcomeHint: 'welcome-hint',
    lastTimeCountryOrLanguageModified: 'last_time_country_lang_modif',
    userId: 'user_id',
    fallbackLang: 'fallback_Lang',
    legalHint: 'legal-hint',
    slideVisited: 'slide-visited',
    cookieAccepted: 'cookieAccepted',
    offlineFavoritesLists: 'offlineFavoritesLists',
    showSafetyTagHint: 'SHOW_SAFETY_TAG_INIT',
    showPopOverSafetyTag: 'SHOW_POP_OVER_SAFETY_TAG'
}
