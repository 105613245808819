import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router'
import { Observable, Subscription, timer } from 'rxjs';
import { tap, map, } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { Article, SearchResult, ISettings, SafetyDataSheet } from '../../types/types';
import { LoadingProvider } from '../../services/loading/loading';
import { DialogService } from '../../services/dialog.service';
import { SDSService } from '../../services/sds.service';
import { ArticleLabel } from '../../services/label-scanner/label-scanner.service';
import { SettingsService } from '../../services/settings.service';
import { Storage } from '@ionic/storage';
import { storageKeys } from '../../services/storage-keys';
import { environment } from '../../env/env';
import { CN } from '../../constants';
import { NavController, PopoverController, ModalController } from '@ionic/angular';
import { ConnectivityProvider } from '../../services/connectivity/connectivity';
import { ExternalLinkService } from '../../services/external-link.service';
import { UtilProvider } from '../../services/util/util';
@Component({
    selector: 'search-list-modal',
    templateUrl: './search-list-modal.html',
    styleUrls: ['./search-list-modal.scss'],
  })
export class SearchListModal {
    @Input() searchValue: string;
    @Input() searchFocus: string;
    @Input() noSearchText: string;
    @ViewChild('popover') popover: any;

    public isWeb = true;
    //public isNoSearchText = true;
    // searchHasFocus: boolean = false;
    public searchTerm: string = '';
    public searchString: string = '';
    public searchResults: Article[];
    public searchHits = -1;
    public searchOffset = 0;
    private searchLimit = 20;
    public help: boolean = true;
    private filterOutIncludes = ['bulk', 'var', 'intr'];
    private filterOutEnd = ['0000', '9999'];
    public filterOut = 'qua';
    public spaceFound: boolean = false;
    chinaWebPortalEnabled = environment.validityArea == CN;
    disabledForWhile = false;
    settings: ISettings;

    isOpen = false;

    @Input() searchItem: Article;
    @Output() onSearchItemClick: EventEmitter<Article> = new EventEmitter<any>();

    articleCasMetadataText: string;

    // settings: Observable<ISettings>;
    searchSubscription: Subscription;
    detailsSubscription: Subscription;
    connectivitySubscription: Subscription;
    settingsSubscription: Subscription;

    constructor(private router: Router,
        public loading: LoadingProvider,
        public sdsService: SDSService,
        private dialogService: DialogService,
        private settingsService: SettingsService,
        private storage: Storage,
        public navCtrl: NavController,
        private connectivity: ConnectivityProvider,
        public popoverController: PopoverController,
        public external: ExternalLinkService,
        public modalCtrl: ModalController,
        private util: UtilProvider
        
        // private routerOutlet: IonRouterOutlet,

    ) {
        this.settingsSubscription = this.settingsService.settings.subscribe((settings) => this.settings = settings);

    }

    ngOnInit() {
        if(this.searchValue){
            
            if(this.searchValue.replace(/ /g, '')){
                this.spaceFound = false
                this.searchArticles(this.searchValue);
            }else{
                this.spaceFound = true
            }
        }

        this.settingsSubscription = this.settingsService.settings.subscribe(settings => {
            if (settings) {
                this.showHelp();
                this.resetSearchTerm();
                this.resetSearchResults();
            }
        });
        this.articleCasMetadataText = this.util.getArticleCasMetadata(this.searchItem);

    }

    ionViewWillUnload(): void {
        // this.connectivitySubscription && this.connectivitySubscription.unsubscribe();
        this.settingsSubscription && this.settingsSubscription.unsubscribe();
    }

    onItemClicked() {
        this.onSearchItemClick.emit(this.searchItem);
    }

    public searchArticles(val: string) {
     //   this.isNoSearchText = false;
      //  this.searchTerm = val.detail.value;
        this.searchTerm = val;
        this.searchString = val;
        if (this.searchTerm && this.searchTerm.length > 2) {
            this.prepareSearch();
            this.searchSubscription && this.searchSubscription.unsubscribe();
            this.searchSubscription = this.performSearchArticles(0)
                .pipe(
                    tap(result => this.handleSearchResult(result)),
                    map(result => result.results))
                .subscribe(
                    results => {
                        return this.searchResults = results.filter(item => {
                            if (item.articleNumber) {
                                item.articleNumber = item.articleNumber.replace("###", ' ');
                            }
                            return this.filterOut_Bulk_Var_0000_9999(item);
                        });
                    },
                    (err) => this.onSearchError(err)
                );
        } else {
            this.resetSearchResults();
        }
    }

    private resetSearchResults() {
        this.loading.stop();
        //this.isNoSearchText = true;
        this.searchResults = null;
        this.searchHits = -1;
    }

    private resetSearchTerm() {
        this.searchTerm = '';
    }

    private onSearchError(error = null) {
        this.loading.stop();
        this.searchResults = null;
        this.searchHits = 0;
        this.searchTerm = ''
        this.dialogService.critical('UNEXPECTED_ERROR', 'ERROR_MESSAGE_APP');
    }

    private performSearchArticles(offset: number): Observable<SearchResult> {
        this.searchOffset = offset;
        return this.sdsService.searchArticleByMaterialNumberAndLimit(null, this.searchTerm, this.searchLimit, this.searchOffset)
    }

    private prepareSearch() {
        this.loading.start();
        this.searchResults = null;
        this.searchHits = -1;
        this.help = false;
    }

    private searchByArticleLabel(articleLabel: ArticleLabel) {
        this.prepareSearch();
        this.searchSubscription && this.searchSubscription.unsubscribe();
        this.searchSubscription = this.sdsService.searchArticleByLabelAndLimit(articleLabel, this.searchLimit, this.searchOffset)
            .pipe(
                tap(result => {
                    this.loading.stop();
                    this.handleSearchResult(result);
                }),
                map(result => result.results),
            )
            .subscribe(
                results => {
                    return this.searchResults = results.filter(item => {
                        if (item.articleNumber) {
                            item.articleNumber = item.articleNumber.replace("###", ' ');
                        }
                        return this.filterOut_Bulk_Var_0000_9999(item);
                    });
                },
                () => this.resetSearchResults()
            );
    }

    private handleSearchResult(searchResult: SearchResult) {
        this.loading.stop();
        if (searchResult) {
            this.searchHits = searchResult.hits ? searchResult.hits : 0;
            if (searchResult.results && searchResult.results.length > 0) {
                this.hideHelp();
                if (searchResult.hits > this.searchLimit) {
                    // this.showToManyResultsToast();
                } else if (searchResult.hits < this.searchLimit) {
                    this.searchOffset = -1;
                }
            }
        }
    }

    private hideHelp() {
        this.help = false;
    }

    private filterOut_Bulk_Var_0000_9999(item: Article): boolean {
        for (let filterItem of this.filterOutIncludes) {
            if (item.materialNumber.toLocaleLowerCase().includes(filterItem)) {
                return false;
            }
        }
        for (let filterItem of this.filterOutEnd) {
            if (item.materialNumber.toLocaleLowerCase().endsWith(filterItem)) {
                return false;
            }
        }
        return item.system.toLowerCase() != this.filterOut;

    }

    onSearchClear() {
        this.showHelp();
    }

    private showHelp() {
        this.help = true;
    }

    public showArticle(selectedArticle: Article) {
        this.loading.start();
        this.detailsSubscription && this.detailsSubscription.unsubscribe();
        this.detailsSubscription = this.sdsService.loadCurrentArticleDetails(selectedArticle)
            .subscribe((sds) => {
                this.checkSDSPresent(selectedArticle, sds);
                document.getElementById("popoverId").style.display = "none";
            }, (error) => {
                this.showArticleDetailsError();
            });
    }


    private checkSDSPresent(selectedArticle: Article, sds: SafetyDataSheet) {
        this.loading.stop();
        if (sds) {
            this.navigateToInfoCategory(selectedArticle, sds);
        } else {
            this.dialogService.critical('ERROR_TITLE_SDS', 'ERROR_MESSAGE_SDS');
        }
    }

    private showArticleDetailsError() {
        this.loading.stop();
        this.dialogService.notify('ERROR_MESSAGE_SDS');
    }

    private navigateToInfoCategory(selectedArticle: Article, sds: SafetyDataSheet) {
        let extras: NavigationExtras = {
            state: {
                selectedArticle: { ...selectedArticle, offlineAvailable: false },
                sds: sds
            },
        }
        this.navigateToNavigationExtras('infocategory', extras);
    }


    private navigateToNavigationExtras(page: string, extras: NavigationExtras) {
        if (this.disabledForWhile) {
            return;
        }
        this.disabledForWhile = true;
        setTimeout(() => {
            this.disabledForWhile = false;
        }, 1000);
        this.router.navigateByUrl('/search',{skipLocationChange:true}).then(()=>this.router.navigate([page], extras))
        
    }

    async onAppLanguageChange(selectedLanguage) {
        this.settings.appLanguage = selectedLanguage;
        this.storage.set(storageKeys.fallbackLang, selectedLanguage);
        await this.updateSettings();
        if (Capacitor.getPlatform() == 'web') {
            // this.navCtrl.navigateRoot("/search", {replaceUrl: true});
            setTimeout(() => {
                window.location.reload();
            }, 500);
        } else {
            this.dialogService.appRestartToast('LANGUAGE_CHANGE');
        }
    }

    async updateSettings() {
        try {
            await this.settingsService.updateSettings(this.settings);
        } catch (err) {
            if (!this.connectivity.checkNetworkConnectivity()) {
                this.dialogService.notify('INTERNET_DISCONNECTED');
            } else {
                this.dialogService.notify('ERROR_MESSAGE_APP');
            }
        }
    }
   
}
