import { Injectable } from '@angular/core';
import { HttpService } from "./http.service";
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../env/env';
import { UserRegistration, CodeConfirmation, ResetPassword, AuthorizationCode, LoginCredentials, UserLoggedIn, IDeleteAccount } from '../classes/_interfaces';
import { TokenService } from "./token.service";
import { FavoritesList, Article, ISettings } from '../types/types';


@Injectable({
  providedIn: 'root',
})
export class SafetyDataAPI {

  private serviceBaseURL = environment.safetyDataAPIBaseURL;
  public linkedInURL: string = 'https://www.linkedin.com/';
  public linkedInAuthorizationURL = `${this.serviceBaseURL}/user/linkedin/authorization`;
  public linkedInAuthorizeURL = `${this.serviceBaseURL}/user/linkedin/authorize?region=${environment.region =='US' ? 'US': 'UK'}`;
  private token: string;

  constructor(public httpService: HttpService,
    private http: HttpClient,
    private tokenService: TokenService) {
    this.tokenService.getContinuous().subscribe(token => {
      this.token = token;
    });
  }


  /**
   * Login with the credentials and if the credentials are valid
   * the server responde with an UserLoggedIn interface.
   *
   * @param credentials
   */
  public login(credentials: LoginCredentials): Promise<UserLoggedIn> {
    return this.httpService.post('/user/auth/login',
      credentials, { baseURL: this.serviceBaseURL });
  }


  /**
   * Send a registry request to the server.
   *
   * @param userRegisterData
   */
  public register(userRegisterData: UserRegistration): Promise<void> {
    return this.httpService.registerPost('/user/registration',
      userRegisterData, { baseURL: this.serviceBaseURL });
  }


  /**
   * Verify the registry using a confirmation code.
   *
   * @param codeConfirmData
   */
  public verifySignup(codeConfirmData: CodeConfirmation): Promise<UserLoggedIn> {
    return this.httpService.post('/user/registration/code',
      codeConfirmData, { baseURL: this.serviceBaseURL });
  }
  /**
   * Lists Favorites provided by the API
   */
  public getFavoritesLists(): Promise<FavoritesList[]> {
    return this.httpService.get('/favorites-lists', { baseURL: this.serviceBaseURL, token: this.token});
  }

  public getFavoritesList(listUUID: string): Promise<FavoritesList> {
    return this.httpService.get('/favorites-lists/' + listUUID, { baseURL: this.serviceBaseURL, token: this.token });
  }

  public createFavoritesList(list: FavoritesList) {
    return this.httpService.post('/favorites-lists',
      list, { baseURL: this.serviceBaseURL, token: this.token});
  }

  public createMultiplesFavoritesList(lists: FavoritesList[]) {
    if (this.token) return this.httpService.post('/favorites-lists/all',
      lists, { baseURL: this.serviceBaseURL, token: this.token, responseType: 'text' });
  }

  public updateFavoritesList(list: FavoritesList) {
    return this.httpService.put(`/favorites-lists/${list.id}`,
      list, { baseURL: this.serviceBaseURL, token: this.token});
  }

  public deleteAllFavoritesList() {
    return this.httpService.delete('/favorites-lists/all',
      { baseURL: this.serviceBaseURL, token: this.token});
  }

  public deleteFavoritesList(listId: number) {
    return this.httpService.delete(`/favorites-lists/${listId}`,
      { baseURL: this.serviceBaseURL, token: this.token});
  }

  public signInWithApple(userData: any): Promise<any> {
    return this.httpService.post('/user/apple',
    userData, { baseURL: this.serviceBaseURL })
  }

  /**
   * favorite item provided by the API
  */
  public addFavoriteToList(listId: number, favorite: Article) {
    return this.httpService.post(`/favorites-lists/${listId}/favorites`,
      favorite, { baseURL: this.serviceBaseURL, token: this.token});
  }

  public removeFavoriteToList(listId: number, favoriteId: number) {
    return this.httpService.delete(`/favorites-lists/${listId}/favorites/${favoriteId}`,
      { baseURL: this.serviceBaseURL, token: this.token});
  }
  /**
   * Sends the authorization code provided by the LinkedIn API
   * and returns the user data.
   *
   * @param authorizationCode
   */
  public linkedInLogin(authorizationCode: AuthorizationCode): Promise<UserLoggedIn> {
    return this.httpService.post('/user/linkedin/login',
      authorizationCode, { baseURL: this.serviceBaseURL })
  }

  public forgotPassword(email: string, recaptcha: string, identifier: string): Promise<void> {
    return this.httpService.post('/user/password/forgot',
      { email, recaptcha, identifier }, { baseURL: this.serviceBaseURL });
  }

  public resetPassword(resetPassword: ResetPassword): Promise<void> {
    return this.httpService.post('/user/password/forgot/new',
      resetPassword, { baseURL: this.serviceBaseURL });
  }

  // Start of settings API
  public getSettings() {
    return this.httpService.get('/settings', { baseURL: this.serviceBaseURL, token: this.token });
  }

  public saveSettings(settings: ISettings) {
    if (this.token) return this.httpService.post('/settings/', settings, { baseURL: this.serviceBaseURL, token: this.token });
  }

  public deleteUserAccount(deleteAccount: IDeleteAccount) {
    return this.httpService.deleteAccount('/user/delete', deleteAccount, { baseURL: this.serviceBaseURL, token: this.token });
  }
  /** 
   * wechat init
  */ 
  public initWechatApis(url: string) {
    return this.httpService.get(`/wechat/keys?path=${url}`,{ baseURL: this.serviceBaseURL, token: this.token });
  }
}
